import "$styles/index.css"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

function submit_contact(event) {
    // pre-fill FormData from the form
    let formData = new FormData(document.forms.contact);

    // send it out
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "https://tgeaiej63b.execute-api.us-east-1.amazonaws.com/prod/contact-form-submit");
    xhr.send(formData);

    xhr.onload = () => alert(xhr.response);
    event.preventDefault();
}

function submit_signup(event) {
    // pre-fill FormData from the form
    let formData = new FormData(document.forms.signup);

    // send it out
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "https://tgeaiej63b.execute-api.us-east-1.amazonaws.com/prod/sign-up-form-submit");
    xhr.send(formData);

    xhr.onload = () => alert(xhr.response);
    event.preventDefault();
}

document.getElementsByClassName('contact-form')[0].onsubmit = submit_contact;
document.getElementsByClassName('sign-up-form')[0].onsubmit = submit_signup;
